import { 
  MS162, 
  MS170, 
  MS172, 
  MS180, 
  MS182, 
  MS210, 
  MS212, 
  MS250, 
  MS260, 
  MS310, 
  MS361, 
  MS382, 
  MS462, 
  MS661, 
  MS780, 
  MS194T, 
  MS201, 
  MSE141, 
  MSE170, 
  FSE52, 
  FSE60, 
  FS38, 
  FS55, 
  FS85, 
  FS120, 
  FS160, 
  FS161, 
  FS220, 
  FS221, 
  FS235, 
  FS280, 
  FS291, 
  FS460, 
  FS511, 
  KA85R, 
  HSE42, 
  HSE61, 
  HS45, 
  HS82, 
  HT56, 
  HT105, 
  BGE71, 
  BG50, 
  SH86, 
  BR420, 
  BR600, 
  SR420, 
  SR450, 
  SG11, 
  SG20, 
  SG31, 
  SG51, 
  SG71, 
  TS420, 
  TS800, 
  BT45, 
  BT131, 
  SE33, 
  RE80, 
  RE90, 
  RE110, 
  RE120, 
  RE145, 
  RE150, 
  RE272, 
  RB200, 
  RB600, 
  MSA160, 
  FSA86, 
  FSA135, 
  BGA86, 
  HSA66, 
  HSA100, 
  HSA30, 
  HLA66, 
  HTA66, 
  HTA86, 
  RMA510, 
  AP200, 
  AP300, 
  AL101, 
  AL300, 
  MSA120, 
  HSA56, 
  BGA57, 
  FSA57, 
  RMA235, 
  RMA460, 
  AK20, 
  AK30, 
  FSA45, 
  HSA45, 
  BGA45, 
  GTA26, 
  HSA26, 
  SEA20, 
  HP725, 
  HP55, 
  HP1, 
  MH710, 
  WP230, 
  WP300, 
  WP600, 
  WP900, 
  EHC705, 
  KOA20 
} from '../MaquinaStihl/index'

export const Maquinaria = [
  {
    id: 'MS162',
    tipo: 'motosierra',
    img: MS162,
    modelo: 'MS 162',
    motor:'30,1 cm3',
    potencia: '1,6 CV',
    peso:'4,5 Kg',
    espada:'30/35 cm',
    descripcion:[
      {
        id:1,
        texto:'La motosierra de gasolina MS 162 es una herramienta básica y fiable para el mantenimiento de propiedades privadas. Tanto si desea cortar leña como cortar árboles frutales, la ligera y robusta motosierra MS 162 es adecuada para un uso ocasional en una amplia gama de aplicaciones. Incluso la tala de árboles más pequeños es posible con esta fiable motosierra.'
      },
      {
        id:2,
        texto:'El sistema general optimizado de la MS 162 incluye el potente motor STIHL 2-MIX. Garantiza un buen rendimiento de corte con un bajo consumo de combustible. Esto te permite realizar cortes precisos de forma fiable, apoyado por la cadena de sierra estándar de 3/8" P con su estrecho corte. Tu motosierra funciona de forma suave y silenciosa: El sistema antivibraciones de STIHL reduce la transmisión de vibraciones a la empuñadura, para que sus músculos y articulaciones se sientan aliviados.'
      },
      {
        id:3,
        texto:'Especialmente cómoda: Gracias al manejo monopalanca, podrá controlar todas las funciones como el arranque, el funcionamiento y la parada con una sola mano. Y la reanudación del trabajo tras una interrupción no supone ningún esfuerzo, ya que la palanca combinada con función de parada vuelve automáticamente a la posición de trabajo. Otra función muy práctica es el retensado seguro y sencillo de la cadena, para lo que resulta especialmente práctico el sistema de tensado lateral de la cadena.'
      },
    ]
  },
  {
    id: 'MS170',
    tipo: 'motosierra',
    img: MS170,
    modelo: 'MS 170',
    motor:'30,1 cm3',
    potencia: '1,6 CV',
    peso:'4,1 Kg',
    espada:'30 cm',
    descripcion:[
      {
        id:1,
        texto:'Práctica motosierra introductoria para entresacar madera, serrar leña y talar árboles pequeños de hasta 30 cm de diámetro aproximadamente. Ligera y fácil de manejar. Cómodo mando principal monopalanca. Disponible con espada de 30 cm.'
      }
    ]
  },
  {
    id: 'MS172',
    tipo: 'motosierra',
    img: MS172,
    modelo: 'MS 172',
    motor:'31,8 cm3',
    potencia: '1,9 CV',
    peso:'4,6 Kg',
    espada:'30/35 cm',
    descripcion:[
      {
        id:1,
        texto:'Cuando se trata de cortar leña o de mantenimiento del terreno, la compacta motosierra de gasolina MS 172 le ofrece una motosierra de gasolina flexible, tanto si es un usuario doméstico como si es un profesional de la horticultura y el paisajismo o un comerciante. Con la MS 172 podrá talar incluso árboles pequeños.'
      },
      {
        id:2,
        texto:'La motosierra de gasolina MS 172 impresiona por su potente motor STIHL 2-MIX, conocido por su excelente rendimiento de corte unido a un bajo consumo de combustible. La cadena de sierra STIHL de 3/8 P de serie garantiza cortes precisos gracias a su estrecho corte. Gracias al sistema antivibraciones STIHL, podrá trabajar con la motosierra MS 172 protegiendo sus músculos y articulaciones. Esto significa que tu motosierra funciona de forma suave y uniforme.'
      },
      {
        id:3,
        texto:'El mando monopalanca es muy cómodo, ya que le permite realizar todas las funciones con una sola mano. Si se toma un descanso, la motosierra está lista para volver a utilizarse inmediatamente. La palanca combinada con función de parada vuelve automáticamente a la posición de trabajo. Otra ventaja del uso de la MS 172 de STIHL: el tensado lateral de la cadena garantiza un retensado seguro y sencillo de la cadena sin contacto con la cadena afilada ni con las puntas del tope de garra.'
      },
    ]
  },
  {
    id: 'MS180',
    tipo: 'motosierra',
    img: MS180,
    modelo: 'MS 180',
    motor:'31,8 cm3',
    potencia: '2 CV',
    peso:'4,1 Kg',
    espada:'40 cm',
    descripcion:[
      {
        id:1,
        texto:'Motosierra compacta y ligera. Muy adecuada para la poda de árboles y el corte de leña. Adecuada para talar árboles de hasta 30 cm de diámetro aproximadamente.'
      }
    ]
  },
  {
    id: 'MS182',
    tipo: 'motosierra',
    img: MS182,
    modelo: 'MS182C-BE',
    motor:'35,8 cm3',
    potencia: '2,2 CV',
    peso:'4,6 Kg',
    espada:'35/40 cm',
    descripcion:[
      {
        id:1,
        texto:'La potente motosierra a explosion MS 182 C-BE es especialmente idónea para cortar leña, así como para su uso en horticultura, paisajismo y mantenimiento de terrenos. Gracias a su motor de alto rendimiento, esta motosierra se puede utilizar para una amplia gama de tareas, tanto si eres usuario doméstico, como un profesional de la horticultura o el paisajismo, o un comerciante.'
      },
      {
        id:2,
        texto:'El potente motor STIHL 2-MIX de la MS 182 C-BE no sólo garantiza un excelente rendimiento de corte, sino que también es económico. Gracias al STIHL ErgoStart, podrás arrancar su motosierra de explosion MS 182 C-BE de forma lenta y uniforme, necesitando menos fuerza durante el arranque. Puedes tensar la cadena de tu motosierra fácilmente y sin utilizar herramientas gracias al tensado rápido de cadena STIHL. El sistema antivibraciones STIHL le ayuda a trabajar cómodamente con tu motosierra, ya que protege tus músculos y articulaciones.'
      },
      {
        id:3,
        texto:'Además, el mando monomando le permite controlar todas las funciones de esta motosierra de explosion con una sola mano. Tu trabajo también se ve facilitado por el tope de garra estable, que te ayuda a sujetar la motosierra firmemente sobre la madera para un corte preciso. El robusto elemento filtrante de material de vellón garantiza largos intervalos de mantenimiento. Hay disponibles filtros especiales de poliamida para trabajar en la nieve.'
      },
      {
        id:4,
        texto:'Otra característica práctica de la MS 182 C-BE: Tras las pausas de trabajo, el motor está inmediatamente listo para arrancar de nuevo, ya que la palanca combinada con función de parada salta automáticamente a la posición de trabajo.'
      }
    ]
  },
  {
    id: 'MS210',
    tipo: 'motosierra',
    img: MS210,
    modelo: 'MS 210',
    motor:'35,2 cm3',
    potencia: '2,2 CV',
    peso:'4,4 Kg',
    espada:'40 cm',
    descripcion:[
      {
        id:1,
        texto:'Ideal para cortar leña. Sistema antivibración STIHL, tapón de combustible sin herramientas.'
      }
    ]
  },
  {
    id: 'MS212',
    tipo: 'motosierra',
    img: MS212,
    modelo: 'MS 212',
    motor:'38,6 cm3',
    potencia: '2,4 CV',
    peso:'4,6 Kg',
    espada:'35/40 cm',
    descripcion:[
      {
        id:1,
        texto:'Motosierra potente y versátil para el cuidado particular de jardines y fincas, para trabajos de jardinería y paisajismo y para construir con madera, así como para talar árboles pequeños.'
      },
      {
        id:2,
        texto:'El motor STIHL 2-MIX ofrece un excelente rendimiento de corte y al mismo tiempo un bajo consumo. Con sistema antivibración STIHL, tensado lateral de cadena, cierre sin herramientas del depósito, mando unificado de posición de arranque con función de parada y manejo con una única palanca para un uso cómodo y sencillo de la motosierra.'
      },
    ]
  },
  {
    id: 'MS250',
    tipo: 'motosierra',
    img: MS250,
    modelo: 'MS 250',
    motor:'45,4 cm3',
    potencia: '3,1 CV',
    peso:'4,6 Kg',
    espada:'45 cm',
    descripcion:[
      {
        id:1,
        texto:'Un paso por encima de la MS 230, la MS 250 es lo suficientemente ligera como para realizar pequeñas tareas de desbroce o limpieza, pero lo suficientemente potente como para cortar pequeñas ramas y árboles. Una combinación de características y una alta relación potencia-peso la convierten en una de las mejores motosierras para el hogar del mercado. Disponible con espada de 14"/35cm, 16"/40cm o 18"/45cm.'
      }
    ]
  },
  {
    id: 'MS251',
    tipo: 'motosierra',
    img: MS250,
    modelo: 'MS 251',
    motor:'45,61 cm3',
    potencia: '3 CV',
    peso:'4,8 Kg',
    espada:'45 cm',
  },
  {
    id: 'MS260',
    tipo: 'motosierra',
    img: MS260,
    modelo: 'MS 260',
    motor:'50,2 cm3',
    potencia: '3,5 CV',
    peso:'4,8 Kg',
    espada:'40/45 cm',
    descripcion:[
      {
        id:1,
        texto:'Práctica, duradera y robusta con un peso mínimo, la STIHL MS 260 puede venir con una serie de características opcionales para hacer el trabajo aún más fácil. Reconocida como una motosierra líder en el sector forestal, está disponible en tamaños de espada de 13"/32cm a 18"/45cm.'
      }
    ]
  },
  {
    id: 'MS310',
    tipo: 'motosierra',
    img: MS310,
    modelo: 'MS 310',
    motor:'59 cm3',
    potencia: '4,4 CV',
    peso:'5,9 Kg',
    espada:'50 cm',
    descripcion:[
      {
        id:1,
        texto:'Adecuada para la tala de madera en rodales de densidad media. Sistema antivibración STIHL, tensor de cadena lateral,y tapones de combustible sin herramientas.'
      }
    ]
  },
  {
    id: 'MS361',
    tipo: 'motosierra',
    img: MS361,
    modelo: 'MS 361',
    motor:'59 cm3',
    potencia: '4,6 CV',
    peso:'5,6 Kg',
    espada:'40/45/50 cm',
    descripcion:[
      {
        id:1,
        texto:'Extraordinaria relación potencia-peso, tecnología de vanguardia y excepcional velocidad de corte y par motor. Equipada de serie con la cadena RSC de STIHL que absorbe las vibraciones. Los niveles de vibraciones más bajos de su clase. Disponible en tamaños de espada de 16"/40cm a 20"/50cm'
      }
    ]
  },
  {
    id: 'MS382',
    tipo: 'motosierra',
    img: MS382,
    modelo: 'MS 382',
    motor:'72,2 cm3',
    potencia: '5,3 CV',
    peso:'6,2 Kg',
    espada:'50/63 cm',
    descripcion:[
      {
        id:1,
        texto:'Ideal para trabajar en puestos de baja y media densidad. Tensor de cadena lateral para un tensado seguro y fácil de la cadena, tapones de combustible sin herramientas para un repostaje sencillo y seguro'
      }
    ]
  },
  {
    id: 'MS462',
    tipo: 'motosierra',
    img: MS462,
    modelo: 'MS 462',
    motor:'72,2 cm3',
    potencia: '6 CV',
    peso:'6 Kg',
    espada:'50/63/75 cm',
    descripcion:[
      {
        id:1,
        texto:'Motosierra de alto rendimiento y peso optimizado para gradas de densidad media a alta con motor económico 2-MIX.  Bomba de aceite de alto rendimiento y caudal controlado para una gran capacidad de bombeo, filtro HD2 con junta radial, tapones de combustible sin herramientas para un repostaje sencillo y seguro.'
      }
    ]
  },
  {
    id: 'MS661',
    tipo: 'motosierra',
    img: MS661,
    modelo: 'MS 661',
    motor:'91,1 cm3',
    potencia: '7,4 CV',
    peso:'7,4 Kg',
    espada:'63/75/90 cm',
    descripcion:[
      {
        id:1,
        texto:'Una motosierra de alto rendimiento muy potente con tecnología 2-MIX de vanguardia. Diseñado para cargas extremas. Relación óptima entre potencia y peso. Bajo nivel de vibración. Filtro de aire HD2 con junta radial. Nuestra mejor recomendación cuando se trata de la recolección efectiva de madera dura.'
      }
    ]
  },
  {
    id: 'MS780',
    tipo: 'motosierra',
    img: MS780,
    modelo: 'MS 780',
    motor:'121,6 cm3',
    potencia: '7,6 CV',
    peso:'9,8 Kg',
    espada:'63/75/90/120/150 cm',
    descripcion:[
      {
        id:1,
        texto:'Sierra de gran potencia para los trabajos forestales más duros. La válvula de descompresión garantiza un arranque sin esfuerzo, el filtro HD2 garantiza una larga vida útil del filtro, los tapones de combustible sin herramientas permiten repostar de forma segura y sencilla.'
      }
    ]
  },
  {
    id: 'MS194',
    tipo: 'motosierra',
    img: MS194T,
    modelo: 'MS 194t',
    motor:'31,8 cm3',
    potencia: '1,9 CV',
    peso:'3,3 Kg',
    espada:'35/40 cm',
    descripcion:[
      {
        id:1,
        texto:'La motosierra a gasolina STIHL MS 194 T es una potente sierra arborista profesional que es igualmente impresionante para el mantenimiento de copas, así como para la eliminación de madera muerta, retoños y brotes de agua . Los especialistas en el mantenimiento de árboles podrán realizar tareas de mantenimiento de forma eficaz con esta motosierra de gasolina. El diseño compacto de la motosierra STIHL MS 194 T y su bajo peso le permiten trabajar de manera eficiente incluso en espacios reducidos.'
      },
      {
        id:2,
        texto:'El potente motor STIHL 2-MIX garantiza un alto rendimiento de corte con un bajo consumo de combustible< /strong>. La cadena de sierra STIHL 1/4 PM3 permite cortes finos y trabajos precisos. El sistema STIHL Ematic también apoya el suministro específico de aceite a la espada y la cadena, lo que significa que puede trabajar de manera efectiva y conservar aceite. Un práctico gancho desplegable sirve para fijar el cable de suspensión al bajar la motosierra y para asegurarla.'
      },
      {
        id:3,
        texto:'Las numerosas ventajas de esta motosierra incluyen el sistema STIHL  de anti-vibración, que minimiza las vibraciones del motor y de la herramienta eléctrica, protegiendo así tus músculos y articulaciones.'
      },
    ]
  },
  {
    id: 'MS201',
    tipo: 'motosierra',
    img: MS201,
    modelo: 'MS 201TC M',
    motor:'35,2 cm3',
    potencia: '2,4 CV',
    peso:'3,7 Kg',
    espada:'35/40 cm',
    descripcion:[
      {
        id:1,
        texto:'La motosierra profesional MS 201 es una de las motosierras de gasolina más ligeras de STHL para trabajos forestales. Todos los modelos de motosierra MS 201 cuentan con un potente motor STIHL 2-MIX con una potencia de 1,8 kW (2,4 CV). Gracias a la tecnología STIHL M-Tronic podrás disfrutar de un rendimiento óptimo del motor en todo momento. Esto se debe a que M-Tronic garantiza un control electrónico preciso del tiempo de encendido y de la dosificación del combustible en cada estado de funcionamiento. STIHL ElastoStart le ayuda a poner en marcha la MS 201. Esta característica amortigua los picos de carga que se producen y protege así sus músculos y articulaciones. La cadena de sierra PS garantiza un gran rendimiento de corte. Las tuercas cautivas en la cubierta de la rueda dentada permiten un reemplazo rápido y fácil de la cadena.'
      },
      {
        id:2,
        texto:'La sierra para arboristas MS 201 TC-M proporciona un soporte óptimo para todos los trabajos de mantenimiento de árboles. Gracias a la espada liviana, el peso máximo del sistema de esta motosierra es de 4,3 kg. La motosierra MS 201 TC-M es la motosierra de gasolina STIHL más potente del mercado.'
      }
    ]
  },
  {
    id: 'MSE141',
    tipo: 'motosierra',
    img: MSE141,
    modelo: 'MS 141 CQ',
    motor:'Eléctrico',
    potencia: '1,4 KW',
    peso:'4,1 Kg',
    espada:'35 cm',
    descripcion:[
      {
        id:1,
        texto:'Particularmente manejable y ligera, la motosierra eléctrica STIHL MSE 141 es ideal para principiantes. Esta motosierra se puede utilizar para cortar leña ypara realizar tareas de carpintería más sencillas en su hogar. Gracias al potente motor y a la cadena de sierra PMM3 de 3/8", puede estar seguro de un rendimiento de corte impresionante con esta motosierra eléctrica. El diseño ergonómico de la motosierra STIHL MSE 141 le ayudará en su trabajo y le ayudará a minimizar la fatiga. La motosierra es también está equipado con una serie de características que le permiten trabajar de la manera más efectiva posible:'
      },
      {
        id:2,
        texto:'Con el tensor de cadena de montaje lateral, puede volver a tensar la cadena rápidamente y sin contacto directo con la cadena de la sierra.'
      },
      {
        id:3,
        texto:'El trabajo seguro se ve favorecido por el freno de cadena QuickStop Super, ya que la motosierra eléctrica se detendrá inmediatamente si sueltas el mango trasero.'
      },
      {
        id:4,
        texto:'La función de protección contra sobrecarga protege la motosierra eléctrica MSE 141 de daños causados por el sobrecalentamiento.'
      },
      {
        id:5,
        texto:'La función de protección contra sobrecarga protege la motosierra eléctrica MSE 141 de daños causados por el sobrecalentamiento.'
      },
      {
        id:6,
        texto:'El sistema STIHL Ematic garantiza una lubricación eficaz de la cadena de la sierra, no sólo haciendo su trabajo más eficaz sino también ahorrando aceite'
      }
    ]
  },
  {
    id: 'MSE170',
    tipo: 'motosierra',
    img: MSE170,
    modelo: 'MS 170C B',
    motor:'Eléctrico',
    potencia: '1,7 KW',
    peso:'4,3 Kg',
    espada:'35 cm',
    descripcion:[
      {
        id:1,
        texto:'La motosierra eléctrica STIHL MSE 170 C-B impresiona por su diseño liviano y su sofisticada ergonomía, que le permiten serrar leña o realizar otras tareas de aserrado en su hogar sin esfuerzo.'
      },
      {
        id:2,
        texto:'Con una potencia de 1,7 kW, el motor STIHL MSE 170 C-B, junto con la cadena de sierra STIHL 3/8" PMM3, ofrece un buen rendimiento de corte. Apreciarás el rápido tensado de la cadena STIHL, especialmente si trabajas con la STIHL MSE 170 C-B con más frecuencia o durante períodos más largos. Esta característica le permite volver a tensar la cadena de forma segura, fácil y sin herramientas según sea necesario. El tope de garra en el STIHL MSE 170 C-B te ayuda a cortar con precisión.'
      },
      {
        id:3,
        texto:'El empuñadura softgrip proporciona un buen y agradable agarre, permitiéndote trabajar cómodamente con la motosierra eléctrica. La motosierra eléctrica STIHL MSE 170 C-B está equipada con protección contra sobrecarga para evitar daños causados por el sobrecalentamiento. La sierra está equipada con freno de cadena QuickStop Super para mayor seguridad al trabajar; esto detiene la cadena de la sierra inmediatamente cuando su mano suelta el mango trasero de la motosierra eléctrica.'
      },
    ]
  },
  {
    id: 'FSE52',
    tipo: 'motoguadaña',
    img: FSE52,
    modelo: 'FSE 52',
    motor:'Eléctrico',
    potencia: '500 W',
    peso:'2,2 Kg',
  },
  {
    id: 'FSE60',
    tipo: 'motoguadaña',
    img: FSE60,
    modelo: 'FSE 60',
    motor:'Eléctrico',
    potencia: '600 W',
    peso:'3,9 Kg',
  },
  {
    id: 'FS38',
    tipo: 'motoguadaña',
    img: FS38,
    modelo: 'FS 38',
    motor:'27,2 cm3',
    potencia: '0,9 cv',
    peso:'4,1 Kg',
  },
  {
    id: 'FS55',
    tipo: 'motoguadaña',
    img: FS55,
    modelo: 'FS 55',
    motor:'27,2 cm3',
    potencia: '0,9 cv',
    peso:'5,1 Kg',
  },
  {
    id: 'FS85',
    tipo: 'motoguadaña',
    img: FS85,
    modelo: 'FS 85',
    motor:'25,4 cm3',
    potencia: '1,3 cv',
    peso:'5,3 Kg',
  },
  {
    id: 'FS120',
    tipo: 'motoguadaña',
    img: FS120,
    modelo: 'FS 120',
    motor:'30,8 cm3',
    potencia: '1,8 cv',
    peso:'6,3 Kg',
  },
  {
    id: 'FS160',
    tipo: 'motoguadaña',
    img: FS160,
    modelo: 'FS 160',
    motor:'29,8 cm3',
    potencia: '1,9 cv',
    peso:'7,4 Kg',
  },
  {
    id: 'FS161',
    tipo: 'motoguadaña',
    img: FS161,
    modelo: 'FS 161',
    motor:'37,7 cm3',
    potencia: '2 cv',
    peso:'7,5 Kg',
  },
  {
    id: 'FS235',
    tipo: 'motoguadaña',
    img: FS235,
    modelo: 'FS 235',
    motor:'36,3 cm3',
    potencia: '2,1 cv',
    peso:'6,8 Kg',
  },
  {
    id: 'FS220',
    tipo: 'motoguadaña',
    img: FS220,
    modelo: 'FS 220',
    motor:'35,2 cm3',
    potencia: '2,3 cv',
    peso:'7,7 Kg',
  },
  {
    id: 'FS221',
    tipo: 'motoguadaña',
    img: FS221,
    modelo: 'FS 221',
    motor:'37,7 cm3',
    potencia: '2,3 cv',
    peso:'7,7 Kg',
  },
  {
    id: 'FS280',
    tipo: 'motoguadaña',
    img: FS280,
    modelo: 'FS 280',
    motor:'38,9 cm3',
    potencia: '2,6 cv',
    peso:'7,9 Kg',
  },
  {
    id: 'FS291',
    tipo: 'motoguadaña',
    img: FS291,
    modelo: 'FS 291',
    motor:'41,6 cm3',
    potencia: '2,7 cv',
    peso:'8,1 Kg',
  },
  {
    id: 'FS460',
    tipo: 'motoguadaña',
    img: FS460,
    modelo: 'FS 460',
    motor:'45,6 cm3',
    potencia: '3 cv',
    peso:'8,4 Kg',
  },
  {
    id: 'FS511',
    tipo: 'motoguadaña',
    img: FS511,
    modelo: 'FS 511',
    motor:'51,6 cm3',
    potencia: '3,3 cv',
    peso:'10,2 Kg',
  },
  {
    id: 'KA85',
    tipo: 'sistema combinado',
    img: KA85R,
    modelo: 'KA 85R',
    motor:'25,4 cm3',
    potencia: '1,3 cv',
    peso:'5 Kg',
  },
  {
    id: 'HSE42',
    tipo: 'cortacerco',
    img: HSE42,
    modelo: 'HSE 42',
    motor:'Eléctrico',
    potencia: '420 W',
    peso:'3 Kg',
    cuchilla: '450 mm',
    descripcion:[
      {
        id:1,
        texto:'El cortasetos eléctrico STIHL HSE 42 es ideal para usuarios privados y puede utilizarse para podar y cortar ramitas y ramas delgadas, y para el mantenimiento de arbustos, bojes y setos pequeños en el exterior de la casa. Con un peso de sólo 3 kg, el cortasetos eléctrico HSE 42 garantiza un funcionamiento silencioso y con pocas vibraciones. Gracias al control a dos manos, puedes manejar la STIHL HSE 42 de forma cómoda y sencilla pulsando simultáneamente los interruptores del mango circular y del mango trasero para arrancarlo. Esto también significa que podrá utilizar el cortasetos cómodamente tanto si es diestro como zurdo. Las cuchillas de corte de un solo lado permiten un un corte limpio. El protector de punta atornillable protege las cuchillas de corte cuando se trabaja cerca del suelo, así como cerca de paredes y otros obstáculos.'
      },
      {
        id:2,
        texto:'Protección contra cortes integrada y un pequeño protector de manos ayuda a garantizar su seguridad cuando trabaja con el cortasetos. Además, el freno mecánico de las cuchillas garantiza que las cuchillas de corte se detengan de forma fiable en menos de un segundo después de apagar el cortasetos eléctrico HSE 42. La función protección contra tirones del cable integrada evita que el enchufe se desconecte si tira del cable de alimentación mientras utiliza la STIHL HSE 42.'
      },
      {
        id:3,
        texto:'Puede utilizar la placa receptora STIHL opcional para recoger los recortes. directamente en la cuchilla de tu STIHL HSE 42.'
      }
    ]
  },
  {
    id: 'HSE61',
    tipo: 'cortacerco',
    img: HSE61,
    modelo: 'HSE 61',
    motor:'Eléctrico',
    potencia: '500 W',
    peso:'4,1 Kg',
    cuchilla: '500 mm',
    descripcion:[
      {
        id:1,
        texto:'El cortasetos eléctrico STIHL HSE 61 es adecuado para usuarios privados que deseen mantener sus setos, bojes y arbustos en el jardín y alrededor de la casa. Con una longitud de barra de 50 cm, el cortasetos eléctrico HSE 61 pesa sólo 3,9 kg y es muy flexible y muy cómodo de usar en todas las posiciones de corte, gracias al < strong>mango multifunción de cinco posiciones. El sistema de triple interruptor le permite aumentar el alcance del cortasetos simplemente moviéndolo desde el soporte del interruptor delantero hasta el extremo trasero del mango. Las cuchillas de corte de un solo lado cortan con precisión incluso ramitas y ramas delgadas. Las hojas cortadas con láser tienen filos endurecidos, lo que las hace especialmente robustas y garantiza que permanezcan afiladas durante mucho tiempo.'
      },
      {
        id:2,
        texto:'Las La protección de punta atornillada protege las cuchillas de corte contra daños al trabajar cerca del suelo o cerca de paredes y otros obstáculos, mientras que la protección contra cortes integrada permite trabajar de forma segura con el cortasetos eléctrico HSE 61. . El protector de tracción del cable integrado garantiza que la herramienta eléctrica no se desenchufe si tira del cable mientras trabaja. Nuestro vídeo sobre cómo realizar un corte trapezoidal en un seto muestra lo versátil que es el cortasetos eléctrico STIHL HSE 61.'
      },
      {
        id:3,
        texto:'Los accesorios opcionales como el plato receptor STIHL le permiten recoger los recortes directamente en la cuchilla del su STIHL HSE 61, por ejemplo.'
      }
    ]
  },
  {
    id: 'HS45',
    tipo: 'cortacerco',
    img: HS45,
    modelo: 'HS 45',
    motor:'27,2 cm3',
    potencia: '1 cv',
    peso:'5 Kg',
    cuchilla: '600 mm',
    descripcion:[
      {
        id: 1,
        texto: 'La cortadora de setos de entrada STIHL HS 45 es ideal para arbustos y el mantenimiento de setos a la altura del pecho. Su diseño ligero y fácil manejo permiten a los propietarios de jardines realizar trabajos de mantenimiento de manera rápida y eficiente. Gracias a STIHL ElastoStart, la STIHL HS 45 se puede arrancar suavemente, ya que se reduce el tirón. Además, la bomba de combustible manual reduce el número de golpes necesarios para el arranque, acelerando así el proceso de inicio y facilitando que puedas comenzar con tus tareas de jardinería. La función de operación de palanca única apoya un manejo cómodo de la cortadora de setos a gasolina STIHL HS 45, ya que todas las funciones de la herramienta se pueden acceder desde la palanca de control. La geometría especial del cortador de las cuchillas de corte de doble cara permite un corte preciso, tanto horizontal como vertical, e incluso en esquinas.'
      },
      {
        id: 2,
        texto: 'Para proteger tus músculos y articulaciones mientras trabajas, el sistema de antivibración STIHL minimiza las vibraciones del motor en la cortadora de setos a gasolina STIHL HS 45. Para un almacenamiento compacto, puedes colgar la cortadora de setos en la pared utilizando el anillo de transporte integrado en el mango. Para asegurarte de que puedas seguir disfrutando de tu cortadora de setos durante mucho tiempo, echa un vistazo a nuestros consejos para el cuidado y mantenimiento regular.'
      },
      {
        id: 3,
        texto: 'La cortadora de setos a gasolina STIHL HS 45 está disponible en dos longitudes de barra: 45 cm y 60 cm.'
      }
    ]
  },
  {
    id: 'HS82',
    tipo: 'cortacerco',
    img: HS82,
    modelo: 'HS 82',
    motor:'22,2 cm3',
    potencia: '1 cv',
    peso:'5,7 Kg',
    cuchilla: '600 mm',
    descripcion:[
      {
        id: 1,
        "texto": 'La cortadora de setos a gasolina STIHL HS 82 R ha sido diseñada sistemáticamente para tareas de poda. La transmisión está especialmente adaptada a bajas revoluciones del motor, lo que resulta en altas fuerzas con bajas velocidades de la cuchilla. Esto es de particular beneficio para los profesionales de la horticultura y el paisajismo, así como para los trabajadores de las autoridades locales. Su geometría de cuchilla, gran separación de dientes y relación de engranaje generan una alta fuerza de corte. Gracias a las cuchillas de corte de doble cara, también puedes realizar una poda precisa al mantener arbustos y setos, por lo que puedes abordar tu trabajo de corte de gran volumen de manera rápida y eficiente.'
      },
      {
        id: 2,
        "texto": 'La cortadora de setos profesional STIHL HS 82 R cuenta con un motor STIHL 2-MIX de alto par. El sistema de antivibración STIHL absorbe las vibraciones del motor para que los largos períodos de trabajo sean lo más cómodos posible, protegiendo tus músculos y articulaciones en el proceso. Con su mango multifuncional giratorio, que puedes girar 90° a la derecha o a la izquierda, puedes usar la cortadora de setos a gasolina STIHL HS 82 R en una posición ergonómica favorable para cada corte.'
      },
      {
        id: 3,
        "texto": 'El botón de parada asegura que el motor esté listo para arrancar de nuevo inmediatamente después de una interrupción en el trabajo. La protección de corte atornillada y el protector de punta te protegen a ti y a las cuchillas mientras trabajas. La STIHL HS 82 está equipada con un tapón de combustible sin herramientas para que puedas rellenar los combustibles lo más rápido posible.'
      },
      {
        id: 4,
        "texto": 'Lee nuestros consejos de mantenimiento de cortadoras de setos para averiguar cómo realizar el cuidado de tu propia cortadora de setos y minimizar el desgaste.'
      }
    ]
  },
  {
    id: 'HT56',
    tipo: 'motosierra',
    img: HT56,
    modelo: 'HT 56',
    motor:'27,2 cm3',
    potencia: '1,1 cv',
    peso:'6,4 Kg',
    altura: '3,9 m',
    descripcion:[
      {
        id:1,
        texto:'La podadora de altura de gasolina STIHL HT 56 C-E es el modelo básico de la gama STIHL y es ideal para el mantenimiento de terrenos y la poda de árboles frutales. El potente motor STIHL 2-MIX ayudará a los propietarios de tierras y jardineros a cortar árboles a alturas significativas. La cadena de sierra PM3 de 1/4" garantiza un corte correcto y preciso. El tensor de cadena lateral permite tensar la cadena de forma segura y cómoda. Para su comodidad, la podadora de altura a gasolina HT 56 C-E está equipada con STIHL ErgoStart, que le permite arranca el motor de forma fácil y sin esfuerzo. La bomba de combustible manual minimiza el número de tirones necesarios para arrancar el motor.'
      },
      {
        id:2,
        texto:'La podadora de pértiga STIHL HT 56 C-E Se puede utilizar con el empuñadura multifunción con una sola mano, que combina todos los elementos de control del motor necesarios, incluido un botón de parada que permite reiniciar rápidamente el HT 56. C-E después de una pausa en el trabajo. Toda la zona del mango está cubierta con una funda cómoda para que siempre pueda mantener un agarre seguro en la motosierra telescópica. Un arnés suavemente acolchado proporciona soporte y ofrece una excelente comodidad de trabajo.'
      },
      {
        id:3,
        texto:'El eje desmontable de la STIHL HT 56 C-E tiene una longitud total de 280 cm y permite un transporte compacto y fácil almacenamiento de su herramienta eléctrica.'
      },
    ]
  },
  {
    id: 'HT105',
    tipo: 'motosierra',
    img: HT105,
    modelo: 'HT 105',
    motor:'31,4 cm3',
    potencia: '1,4 cv',
    peso:'7,9 Kg',
    altura: 'Hasta 4 m',
    descripcion:[
      {
        id:1,
        texto:'Potente podadora de altura de 1,05 kW para usuarios profesionales que trabajan en el mantenimiento de árboles, huertos y mantenimiento municipal. Para podar árboles, retirar madera muerta y roturas de tormentas y para cortar árboles frutales. Cadena de sierra PM3 de ¼", transmisión ligera de magnesio con gancho de rama robusto y moldeado para retirar fácilmente los recortes sueltos del árbol. Eje telescópico profesional especialmente rígido y guiable con ajuste rápido para un comportamiento de mordida preciso y un corte preciso. Tuerca cautiva e integrada Guía de dirección en la tapa del piñón. Motor 4-MIX® con sistema de arranque simplificado. Expulsión de viruta lateral para reducir la obstrucción. Longitud total 270-390 cm.'
      },
    ]
  },
  {
    id: 'BGE71',
    tipo: 'sopladora',
    img: BGE71,
    modelo: 'BGE 71',
    motor:'Eléctrico',
    potencia: '1100 W',
    peso:'3 Kg',
    caudal: '670 m3/h',
    velocidad: '237,6 Km/h'
  },
  {
    id: 'BG50',
    tipo: 'sopladora',
    img: BG50,
    modelo: 'BG 50',
    motor:'27,2 cm3',
    potencia: '0.95 cv',
    peso:'3,6 Kg',
    caudal: '700 m3/h',
    velocidad: '216 Km/h'
  },
  {
    id: 'SH86',
    tipo: 'sopladora',
    img: SH86,
    modelo: 'SH 86',
    motor:'27,2 cm3',
    potencia: '1,1 cv',
    peso:'5,7 Kg',
    caudal: '770 m3/h',
    velocidad: '306 Km/h'
  },
  {
    id: 'BR420',
    tipo: 'sopladora',
    img: BR420,
    modelo: 'BR 420',
    motor:'56,5 cm3',
    potencia: '3,4 cv',
    peso:'9,1 Kg',
    caudal: '1260 m3/h',
    velocidad: '280 Km/h'
  },
  {
    id: 'BR600',
    tipo: 'sopladora',
    img: BR600,
    modelo: 'BR 600',
    motor:'64,8 cm3',
    potencia: '3,9 cv',
    peso:'9,8 Kg',
    caudal: '1720 m3/h',
    velocidad: '324 Km/h'
  },
  {
    id: 'SR420',
    tipo: 'pulverizadora',
    img: SR420,
    modelo: 'SR 420',
    motor:'56,5 cm3',
    potencia: '3,5 cv',
    peso:'11,1 Kg',
    alcance: '12 m',
    capacidad: '13 L'
  },
  {
    id: 'SR450',
    tipo: 'pulverizadora',
    img: SR450,
    modelo: 'SR 450',
    motor:'63,3 cm3',
    potencia: '3,9 cv',
    peso:'12,8 Kg',
    alcance: '14,5 m',
    capacidad: '14 L'
  },
  {
    id: 'SG11',
    tipo: 'pulverizadora',
    img: SG11,
    modelo: 'SG 11',
    peso:'0,46 Kg',
    capacidad: '1,5 L',
    presion: '1-3 bares'
  },
  {
    id: 'SG20',
    tipo: 'pulverizadora',
    img: SG20,
    modelo: 'SG 20',
    peso:'5,2 Kg',
    capacidad: '18 L',
    presion: '2-6 bares'
  },
  {
    id: 'SG31',
    tipo: 'pulverizadora',
    img: SG31,
    modelo: 'SG 31',
    peso:'1,3 Kg',
    capacidad: '5 L',
    presion: '1-3 bares'
  },
  {
    id: 'SG51',
    tipo: 'pulverizadora',
    img: SG51,
    modelo: 'SG 51',
    peso:'4,8 Kg',
    capacidad: '12 L',
    presion: '2-6 bares'
  },
  {
    id: 'SG71',
    tipo: 'pulverizadora',
    img: SG71,
    modelo: 'SG 71',
    peso:'4,8 Kg',
    capacidad: '18 L',
    presion: '2-6 bares'
  },
  {
    id: 'TS420',
    tipo: 'hoyadora',
    img: TS420,
    modelo: 'TS 420',
    motor:'66,7 cm3',
    potencia: '4,4 cv',
    peso:'9,6 Kg',
    diametro: '350 mm',
    profundidad: '125 mm'
  },
  {
    id: 'TS800',
    tipo: 'hoyadora',
    img: TS800,
    modelo: 'TS 800',
    motor:'98,5 cm3',
    potencia: '6,8 cv',
    peso:'12,7 Kg',
    diametro: '400 mm',
    profundidad: '145 mm'
  },
  {
    id: 'BT131',
    tipo: 'hoyadora',
    img: BT131,
    modelo: 'BT 131',
    motor:'36,3 cm3',
    potencia: '1,9 cv',
    peso:'10 Kg',
    diametro: '300 mm',
  },
  {
    id: 'BT45',
    tipo: 'hoyadora',
    img: BT45,
    modelo: 'BT 45',
    motor:'27,2 cm3',
    potencia: '1,1 cv',
    peso:'4,8 Kg',
  },
  {
    id: 'SE33',
    tipo: 'hidrolavadora',
    img: SE33,
    modelo: 'SE 33',
    motor:'Eléctrico',
    potencia: '1,4 Kw',
    peso:'5,4 Kg',
    capacidad: '12 L'
  },
  {
    id: 'RE80',
    tipo: 'hidrolavadora',
    img: RE80,
    modelo: 'RE 80',
    motor:'Eléctrico',
    potencia: '2,1 Kw',
    peso:'8 Kg',
    presion: '90 bares',
    caudalAgua: '330 l/h'
  },
  {
    id: 'RE90',
    tipo: 'hidrolavadora',
    img: RE90,
    modelo: 'RE 90',
    motor:'Eléctrico',
    potencia: '2,1 Kw',
    peso:'8 Kg',
    presion: '100 bares',
    caudalAgua: '440 l/h'
  },
  {
    id: 'RE110',
    tipo: 'hidrolavadora',
    img: RE110,
    modelo: 'RE 110',
    motor:'Eléctrico',
    potencia: '1,7 Kw',
    peso:'17,6 Kg',
    presion: '110 bares',
    caudalAgua: '440 l/h'
  },
  {
    id: 'RE120',
    tipo: 'hidrolavadora',
    img: RE120,
    modelo: 'RE 120',
    motor:'Eléctrico',
    potencia: '2,1 Kw',
    peso:'20 Kg',
    presion: '120 bares',
    caudalAgua: '500 l/h'
  },
  {
    id: 'RE145',
    tipo: 'hidrolavadora',
    img: RE145,
    modelo: 'RE 145',
    motor:'Eléctrico',
    potencia: '2,1 Kw',
    peso:'18,5 Kg',
    presion: '110 bares',
    caudalAgua: '580 l/h'
  },
  {
    id: 'RE150',
    tipo: 'hidrolavadora',
    img: RE150,
    modelo: 'RE 150',
    motor:'Eléctrico',
    potencia: '2,2 Kw',
    peso:'30 Kg',
    presion: '120 bares',
    caudalAgua: '522 l/h'
  },
  {
    id: 'RE272',
    tipo: 'hidrolavadora',
    img: RE272,
    modelo: 'RE 272 PLUS',
    motor:'Eléctrico',
    potencia: '3 Kw',
    peso:'42 Kg',
    presion: '150 bares',
    caudalAgua: '620 l/h'
  },
  {
    id: 'RB200',
    tipo: 'hidrolavadora',
    img: RB200,
    modelo: 'RB 200',
    motor:'173 cm3',
    potencia: '5 cv',
    peso:'28 Kg',
    presion: '170 bares',
    caudalAgua: '520 l/h'
  },
  {
    id: 'RB600',
    tipo: 'hidrolavadora',
    img: RB600,
    modelo: 'RB 600',
    motor:'208 cm3',
    potencia: '7 cv',
    peso:'53,5 Kg',
    presion: '220 bares',
    caudalAgua: '680 l/h'
  },
  {
    id: 'MSA160CB',
    tipo: 'AP',
    img: MSA160,
    modelo: 'MSA 160',
    motor:'Batería AP',
    peso:'3,3 Kg',
    espada: '30 cm'
  },
  {
    id: 'FSA86R',
    tipo: 'AP',
    img: FSA86,
    modelo: 'FSA 86',
    motor:'Batería AP',
    peso:'3,4 Kg',
    diametro: '35 cm'
  },
  {
    id: 'FSA135',
    tipo: 'AP',
    img: FSA135,
    modelo: 'FSA 135',
    motor:'Batería AP',
    peso:'4,9 Kg',
    diametro: '42 cm'
  },
  {
    id: 'BGA86',
    tipo: 'AP',
    img: BGA86,
    modelo: 'BGA 86',
    motor:'Batería AP',
    peso:'2,8 Kg',
    caudal: '780 m3/h',
    velocidad: '210 Km/h'
  },
  {
    id: 'HSA66',
    tipo: 'AP',
    img: HSA66,
    modelo: 'HSA 66',
    motor:'Batería AP',
    peso:'3,1 Kg',
    cuchilla: '500 mm',
    descripcion:[
      {
        id:1,
        texto:'El cortasetos con batería STIHL HSA 66 es una buena opción para usuarios privados que desean recortar setos en zonas sensibles al ruido. El cortasetos a batería HSA 66 de STIHL funciona con baterías de iones de litio del sistema STIHL AP. Incluso al afrontar tareas desafiantes, por ejemplo cortar ramas más gruesas, la frecuencia de carrera del motor EC libre de mantenimiento de la STIHL HSA 66 permanece constante. El sistema de cuchillas con su geometría de corte en forma de lágrima garantiza que las ramitas se mantengan más seguras en la cuchilla mientras corta. El ángulo de afilado optimizado también favorece el fuerte rendimiento de corte de las cuchillas de corte unilateral. Arrancar la STIHL HSA 66 es fácil gracias a su función de control a dos manos. Para encender la herramienta eléctrica, simplemente presione los interruptores en el mango circular y en el mango trasero simultáneamente. Esto también significa que podrá utilizar el cortasetos cómodamente tanto si es diestro como zurdo. El mango circular también permite el manejo sin esfuerzo de la STIHL HSA 66 en cualquier dirección de trabajo. La protección contra cortes integrada y el protector de punta atornillado del cortasetos a batería garantizan una mayor seguridad y protegen el accesorio de corte cuando se trabaja cerca del suelo.'
      },
      {
        id:2,
        texto:'Consulta nuestra descripción general de la duración de la batería STIHL y los tiempos de carga para ver cómo cuánto tiempo puedes trabajar con tu cortasetos a batería STIHL HSA 66 y cuánto tarda la batería en cargarse. Nuestra práctica guía le informará sobre las ventajas de las baterías de iones de litio y cómo cargar las baterías STIHL.'
      },
      {
        id:3,
        texto:'Todas las herramientas eléctricas a baterías del sistema STIHL AP han sido diseñadas para aplicaciones profesionales y el trabajo diario, incluso en Condiciones climáticas adversas. Teniendo esto en cuenta, están certificados como a prueba de salpicaduras; la eficacia de esto también se verifica mediante exigentes controles internos. La prueba de salpicaduras de agua está alineada con el estándar IPX4, entre otros.'
      }
    ]
  },
  {
    id: 'HSA100',
    tipo: 'AP',
    img: HSA100,
    modelo: 'HSA 100',
    motor:'Batería AP',
    peso:'3,7 Kg',
    cuchilla: '600 mm',
    descripcion:[
      {
        id:1,
        texto:'El cortacercos sin cable STIHL HSA 100 ofrece una ayuda eficaz en la horticultura y el paisajismo, así como en las administraciones públicas, para recortar y podar de forma eficiente y cómoda grandes cercos en zonas sensibles al ruido. El STIHL HSA 100 es un cortacercos de muy alto rendimiento con motor EC que no requiere mantenimiento. El motor funciona con baterías de iones de litio del sistema AP de STIHL. Ofrece siempre una velocidad de carrera constante, incluso bajo carga, y también al cortar ramas más gruesas. La cuchilla de corte de doble cara proporciona cortes precisos, tanto al recortar como al podar. Si las ramas duras bloquean el sistema de cuchillas, puede volver a arrancar rápidamente con sólo cambiar el sentido de giro.'
      },
      {
        id:2,
        texto:'La empuñadura multifunción mejorada se puede girar 90° a la izquierda y a la derecha con sólo pulsar un botón, lo que le permite utilizar su STIHL HSA 100 para cortar de forma muy ergonómica en diferentes posiciones, por ejemplo, al cortar de lado o a la altura de la cabeza. De este modo, podrá guiar fácilmente su cortasetos tanto con la mano izquierda como con la derecha. Además, la empuñadura en bucle permite trabajar sin esfuerzo con el cortasetos. El sistema antivibraciones STIHL reduce las vibraciones del motor, protegiendo sus músculos y articulaciones durante largos periodos de trabajo.'
      },
      {
        id:3,
        texto:'La protección contra cortes atornillada reduce el riesgo de contacto con las cuchillas de corte, favoreciendo así un trabajo seguro con el HSA 100 de STIHL. Al cortar cerca del suelo o en paredes, el accesorio de corte está protegido contra el desgaste gracias al protector de puntas atornillable.'
      }
    ]
  },
  {
    id: 'HLA66',
    tipo: 'AP',
    img: HLA66,
    modelo: 'HLA 66',
    motor:'Batería AP',
    peso:'3,8 Kg',
    cuchilla: '500 mm',
    descripcion:[
      {
        id:1,
        texto:'Cortacercos de largo alcance a batería y ligero para podar cercos y arbustos, así como plantas cerca del suelo. La barra de corte se puede ajustar hasta 115° sin el uso de herramientas para el corte a la altura del cabezal, lateral y al nivel del suelo. Hoja de corte de doble cara para un patrón de corte limpio. Carrera constante de la cuchilla y progreso del trabajo bajo carga Mango de bucle, control de velocidad infinitamente variable y mango de control intuitivo. Longitud de transporte con barra de corte plegada 125 cm; longitud total 205 cm.'
      }
    ]
  },
  {
    id: 'HTA66',
    tipo: 'AP',
    img: HTA66,
    modelo: 'HTA 66',
    motor:'Batería AP',
    peso:'3,3 Kg',
    espada: '30 cm',
    altura: '2,4 m',
    descripcion:[
      {
        id:1,
        texto:'Podadora de altura a batería muy ligera y silenciosa pero potente para el mantenimiento de terrenos y jardines. Con cadena de sierra PM3 de ¼", cabezal de accionamiento ligero con gancho para ramas y eje rígido. Longitud total 240cm'
      }
    ]
  },
  {
    id: 'HTA86',
    tipo: 'AP',
    img: HTA86,
    modelo: 'HTA 86',
    motor:'Batería AP',
    peso:'4,6 Kg',
    espada: '30 cm',
    altura: '2,7 - 3,9 m',
    descripcion:[
      {
        id:1,
        texto:'Potente podadora de altura de batería. Silenciosa y con bajo nivel de vibraciones. Recomendada para ayuntamientos y usuarios profesionales en trabajos de poda, saneamiento de ramas muertas o dañadas, así como poda de frutales. Cadena 1/4" PM3, cabezal de corte ligero y compacto con gancho para la recogida de ramas enganchadas y tubo telescópico profesional especialmente rígido con ajuste rápido para una colocación exacta en la rama y un corte preciso. Longitud total 270 – 390 cm.'
      }
    ]
  },
  {
    id: 'RMA510',
    tipo: 'AP',
    img: RMA510,
    modelo: 'RMA 510',
    motor:'Batería AP',
    peso:'28 Kg',
    diametro: '51 cm',
    altura: '3,5-9 cm'
  },
  {
    id: 'AP200',
    tipo: 'AP',
    img: AP200,
    modelo: 'BATERIA AP 200',
    peso:'1,2 Kg',
    energia: '187 Wh'
  },
  {
    id: 'AP300',
    tipo: 'AP',
    img: AP300,
    modelo: 'BATERIA AP 300',
    peso:'1,8 Kg',
    energia: '227 Wh'
  },
  {
    id: 'AL300',
    tipo: 'AP',
    img: AL300,
    modelo: 'CARGADOR AL 300',
    carga:'Rápida',
  },
  {
    id: 'AL101',
    tipo: 'AP',
    img: AL101,
    modelo: 'CARGADOR AL 101',
    carga:'Normal',
  },
  {
    id: 'MSA120CB',
    tipo: 'AK',
    img: MSA120,
    modelo: 'MSA 120',
    motor:'Batería AK',
    peso:'2,6 Kg',
    espada: '30 cm'
  },
  {
    id: 'HSA56',
    tipo: 'AK',
    img: HSA56,
    modelo: 'HSA 56',
    motor:'Batería AK',
    peso:'2,9 Kg',
    cuchilla: '45 cm'
  },
  {
    id: 'BGA57',
    tipo: 'AK',
    img: BGA57,
    modelo: 'BGA 57',
    motor:'Batería AK',
    peso:'2,3 Kg',
    caudal: '620 m3/h',
    velocidad: '165 km/h'
  },
  {
    id: 'FSA57',
    tipo: 'AK',
    img: FSA57,
    modelo: 'FSA 57',
    motor:'Batería AK',
    peso:'2,7 Kg',
    diametro: '26 cm'
  },
  {
    id: 'RMA235',
    tipo: 'AK',
    img: RMA235,
    modelo: 'RMA 235',
    motor:'Batería AK',
    peso:'14 Kg',
    diametro: '33 cm',
    altura: '2,5-6 cm'
  },
  {
    id: 'RMA460',
    tipo: 'AK',
    img: RMA460,
    modelo: 'RMA 460',
    motor:'Batería AK',
    peso:'25 Kg',
    diametro: '46 cm',
    altura: '3,5-9,5 cm'
  },
  {
    id: 'AK20',
    tipo: 'AK',
    img: AK20,
    modelo: 'BATERIA AK 20',
    peso:'1,2 Kg',
    energia: '144 Wh'
  },
  {
    id: 'AK30',
    tipo: 'AK',
    img: AK30,
    modelo: 'BATERIA AK 30',
    peso:'1,3 Kg',
    energia: '180 Wh'
  },
  {
    id: 'Ak101',
    tipo: 'AK',
    img: AL101,
    modelo: 'CARGADOR AL 101',
    carga:'Normal',
  },
  {
    id: 'FSA45',
    tipo: 'AI',
    img: FSA45,
    modelo: 'FSA 45',
    motor:'Batería AI',
    peso:'2,3 Kg',
    diametro: '23 cm'
  },
  {
    id: 'BGA45',
    tipo: 'AI',
    img: BGA45,
    modelo: 'BGA 45',
    motor:'Batería AI',
    peso:'2 Kg',
    velocidad: '38 m/s',
    caudal: '420 m3/h'
  },
  {
    id: 'hsa45',
    tipo: 'AI',
    img: HSA45,
    modelo: 'HSA 45',
    motor:'Batería AI',
    peso:'2,3 Kg',
    cuchilla: '50 cm'
  },
  {
    id: 'GTA26',
    tipo: 'AS',
    img: GTA26,
    modelo: 'GTA 26',
    motor:'Batería AS',
    peso:'1,2 Kg',
    espada: '10 cm'
  },
  {
    id: 'SEA20',
    tipo: 'AS',
    img: SEA20,
    modelo: 'SEA 20',
    motor:'Batería AS',
    peso:'1,1 Kg',
    descripcion:[
      {
        id:1,
        texto:'El práctico aspirador de mano a batería STIHL SEA 20 está diseñado para que los usuarios domésticos puedan limpiar de forma rápida y sencilla pequeñas zonas del hogar. Si además necesita una batería y un cargador del Sistema STHIL AS , puede pedirlos directamente en el set. Como estándar, recibirá una batería de iones de litio STIHL AS 2  y un cargador estándar STIHL AL 1. Así tendrá todo a mano para limpiar rápidamente las zonas más pequeñas de la casa y sus alrededores. Con la STIHL SEA 20 podrá limpiar de forma fácil, cómoda y silenciosamente. La potente aspiradora de mano de 10 V de STIHL es ligera y ergonómica.'
      },
      {
        id:2,
        texto:'La suave empuñadura permite accionar el botón de arranque cómodamente tanto con la mano derecha como con la izquierda y sujeta la STIHL SEA 20 de forma segura en la mano. La herramienta eléctrica dispone de una solución de bloqueo para facilitar el funcionamiento continuo sin tener que mantener presionada la palanca de control. Una trampilla antirretorno flexible detrás de la boquilla de succión mantiene de manera confiable la suciedad aspirada dentro de la caja de recolección y es fácil de quitar cuando necesita vaciar y limpiar la caja. Se consigue un rendimiento de filtrado excelente con el sistema de filtrado de dos etapas, y el filtro también se puede limpiar y reutilizar fácilmente.'
      },
      {
        id:3,
        texto:'El juego de aspirador de mano STIHL SEA 20 con batería AS 2 y el cargador estándar AL 1 incluye varios accesorios prácticos de serie: boquilla para juntas, boquilla para suciedad gruesa, manguera de aspiración, tubo de extensión, filtro de papel y cesta de filtro. Entre trabajos, el aspirador de mano a batería STHIL SEA 20 se puede guardar en la bolsa de almacenamiento suministrada con anilla de transporte para ahorrar espacio.'
      },
      {
        id:4,
        texto:'Nuestra descripción general de la duración de la batería STIHL y los tiempos de carga  muestra cuánto tiempo puede trabajar con su STIHL SEA 20 y cuánto tiempo tarda en cargarse la batería instalada.'
      },
      {
        id:5,
        texto:'El STIHL SEA 20 es compatible con el STIHL Smart Connector 1.0, que se puede actualizar. Puede utilizarlo para registrar el tiempo de funcionamiento y luego mantener una visión general de estos y otros parámetros a través de la aplicación STIHL Connected.'
      },
    ]
  },
  {
    id: 'HSA26',
    tipo: 'AS',
    img: HSA26,
    modelo: 'HSA 26',
    motor:'Batería AS',
    peso:'0,7 Kg',
    cuchilla: '12/20 cm',
    descripcion:[
      {
        id:1,
        "texto":'El juego de cortasetos con batería STIHL HSA 26 es adecuado para tareas domésticas como el mantenimiento de setos más pequeños de hoja perenne y plantas leñosas ornamentales de hojas pequeñas como los bojes. También puedes utilizar el cortasetos para recortar los bordes del césped.'
      },
      {
        id:2,
        "texto":'El cortasetos inalámbrico está equipado de serie con una cuchilla para arbustos y un cortacésped. Con el cortasetos en forma de lágrima y su geometría de hoja optimizada, las ramitas finas se sujetan de forma fiable en la hoja de corte para obtener resultados de corte óptimos. Puede utilizar la cortabordes de césped para recortar y cortar eficazmente hierba o bordes de césped. Las cuchillas se pueden cambiar sin el uso de herramientas. Con mango de control ergonómicamente optimizado, el cortaarbustos es seguro y cómodo de usar. Su peso de sólo 0,8 kg (sin batería) y el eje telescópico opcional le permiten trabajar cómodamente en posición vertical. Con solo presionar un botón, el indicador LED de nivel de carga con 4 LED muestra la carga restante y el tiempo de funcionamiento.'
      },
      {
        id:3,
        "texto":'Nuestra descripción general de la duración de la batería STIHL y los tiempos de carga muestra cuánto tiempo puede trabajar con su cortasetos STIHL HSA 26 y cuánto tiempo tarda en cargarse la batería de iones de litio instalada.'
      },
      {
        id:4,
        "texto":'Incluido en el set: 1x cortasetos inalámbrico STIHL HSA 26 - 1 Batería de iones de litio STIHL AS 2 - 1 cargador estándar AL 1 - 1 cortasetos, barra de 20 cm de longitud, incluida funda para cuchillas - 1 cortacésped, corte de 12 cm de ancho, incluida la funda de la cuchilla'
      }
    ]
  },
  {
    id: 'HSA30',
    tipo: 'AS',
    img: HSA30,
    modelo: 'HSA 30',
    motor:'Batería AS',
    peso:'2 Kg',
    cuchilla: '45 cm',    descripcion:[
      {
        id:1,
        texto:'El cortacercos a batería STIHL HSA 30, especialmente ligero y silencioso, impresiona por su diseño ergonómico y equilibrio a la hora de cortar setos y cuidar el jardín en casa, además de por su diseño compacto. '
      },
      {
        id:2,
        texto:'La óptima coordinación entre el motor eléctrico, la gestión inteligente de la energía de la batería y las cuchillas afiladas con láser, afiladas con diamante y endurecidas garantiza un fuerte rendimiento de corte - especialmente con brotes jóvenes.'
      },
      {
        id:3,
        texto:'Para un manejo fácil y seguro, la STIHL HSA 30 tiene un agarre a dos manos. La máquina se pone en marcha pulsando simultáneamente los interruptores de la empuñadura y de la empuñadura trasera, cuyas superficies de agarre suave garantizan además un agarre notablemente bueno en todas las direcciones de trabajo. La guía de protección atornillada protege las cuchillas de corte de posibles daños, por ejemplo, al trabajar cerca del suelo o en contacto con paredes.'
      },
      {
        id:4,
        texto:'Para un almacenamiento seguro y que ahorre espacio, el STIHL HSA 30 dispone de una práctica pata para colgar en la empuñadura trasera.'
      }
    ]
  },
  {
    id: '7,25HP',
    tipo: 'cortadora de cesped',
    img: HP725,
    modelo: 'Plumita 7,25 HP',
    motor:'Briggs & Stratton 4T',
    potencia: '7,25 cv',
    diametro:'50 cm. Colector y salida lateral',
  },
  {
    id: '5,5HP',
    tipo: 'cortadora de cesped',
    img: HP55,
    modelo: 'Plumita 5,5 HP',
    motor:'Zongshen 4T',
    potencia: '5,5 cv',
    diametro:'50 cm. Colector y salida lateral',
  },
  {
    id: '1HP',
    tipo: 'cortadora de cesped',
    img: HP1,
    modelo: 'Plumita 1 HP',
    motor:'Eléctrico',
    potencia: '1400 W',
    diametro:'40 cm',
  },
  {
    id: 'MH710',
    tipo: 'motocultivador',
    img: MH710,
    modelo: 'MH 710',
    motor:'252 cm3',
    potencia: '7,0 CV',
    peso:'102 Kg',
    descripcion:[
      {
        id:1,
        "texto":'MH 710 Motocultivador es la combinación perfecta entre robustez, agilidad y rendimiento. Este equipo ayudará a rentabilizar su trabajo, ya que está preparada para las más extensas y exigentes jornadas de labores en cualquier tipo de suelo.'
      },
      {
        id:2,
        "texto":'Su poderoso motor de 7,0 Hp en conjunto con sus componentes reforzados, garantizan un rendimiento excepcional incluso en los terrenos más desafiantes, brindándole a la vez un alto grado de comodidad debido al manillar ergonómico ajustable, la excelente distribución del peso y las mínimas vibraciones durante el funcionamiento.'
      }
    ]
  },
  {
    id: 'WP230',
    tipo: 'motobomba',
    img: WP230,
    modelo: 'WP 230',
    motor:'40,2 cm3',
    potencia: '2,11 CV',
    peso:'11,3 Kg',
    caudal: '15 m3/h',
  },
  {
    id: 'WP300',
    tipo: 'motobomba',
    img: WP300,
    modelo: 'WP 300',
    motor:'212 cm3',
    potencia: '6,0 CV',
    peso:'26 Kg',
    caudal: '37 m3/h',
  },
  {
    id: 'WP600',
    tipo: 'motobomba',
    img: WP600,
    modelo: 'WP 600',
    motor:'212 cm3',
    potencia: '6,0 CV',
    peso:'29 Kg',
    caudal: '63 m3/h',
  },
  {
    id: 'WP900',
    tipo: 'motobomba',
    img: WP900,
    modelo: 'WP 900',
    motor:'252 cm3',
    potencia: '7,1 CV',
    peso:'38 Kg',
    caudal: '94 m3/h',
  },
  {
    id: 'EHC705',
    tipo: 'motor',
    img: EHC705,
    modelo: 'EHC 705 S',
    motor:'252 cm3',
    potencia: '7,0 CV',
    descripcion:[
      {
        id:1,
        "texto":'El motor de gasolina de 4 tiempos EHC 705 S de STIHL le ofrece un centro de fuerza fijo, de alto rendimiento y robusto, adecuado para diversas actividades agrícolas y de construcción. El eje de transmisión forjado permite acoplar cómodamente diversas herramientas para facilitarle el trabajo diario.'
      },
      {
        id:2,
        "texto":'El compacto y fiable STIHL EHC 705 S proporciona hasta 5,2 kW (7 CV) para diversas aplicaciones. Con un bajo consumo de combustible y un volumen de depósito de 4,0 litros, ofrece la máxima capacidad para trabajar sin interrupciones. La STIHL EHC 705 S es fácil de manejar y requiere poco mantenimiento.'
      }
    ]
  },
  {
    id: 'KOA20',
    tipo: 'AS',
    img: KOA20,
    modelo: 'KOA 20',
    motor:'Batería AS',
    peso:'1,5 Kg',
    descripcion:[
      {
        id:1,
        texto:'¿Aún no tienes una batería ni un cargador del sistema STIHL AS? Entonces este práctico set es ideal para ti. Además del compresor de batería STIHL KOA 20, recibirás una batería STIHL AS 2 y un cargador estándar STIHL AL 1. Esto significa que podrás comenzar a trabajar inmediatamente después de la entrega.'
      },
      {
        id:2,
        texto:'El compacto compresor de batería STIHL KOA 20 es ideal para inflar de manera rápida y sin esfuerzo los neumáticos de autos o bicicletas, así como para desinflar de forma conveniente y rápida equipos de ocio y deportes, como pelotas o botes inflables. No solo es útil en casa, sino también cuando estás de viaje, por ejemplo, cuando es necesario inflar las ruedas de un remolque.'
      },
      {
        id:3,
        texto:'El compresor de batería STIHL KOA 20 es fácil de usar. Para aplicaciones versátiles, puedes realizar varios ajustes en el KOA 20 a través de su pantalla ergonómica, que es fácil de ver desde arriba. Por ejemplo, puedes elegir entre la función de alta presión y la función de volumen con solo tocar un botón, ajustar la presión deseada y verificar el nivel de carga de la batería. Durante el proceso de inflado y desinflado, los pies de goma del KOA 20 aseguran una estabilidad segura y de baja vibración, y la brillante luz LED garantiza una buena visibilidad en la oscuridad. El STIHL KOA 20 también tiene una función de apagado automático: tan pronto como se alcanza la presión deseada, la bomba detiene el proceso de inflado y el dispositivo se apaga automáticamente después de aproximadamente 5 minutos de inactividad. Esto ahorra energía y aumenta la vida útil de la batería. Además de las mangueras de presión y volumen, también se incluyen varios adaptadores de válvula en el alcance de la entrega. Tanto las mangueras como los adaptadores se pueden almacenar directamente en el compresor de batería KOA 20, por lo que siempre estarán al alcance.'
      }
    ]
  },
]