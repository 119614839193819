import {
  M236,
  M200,
  TC591,
  TC950,
  TC140,
  TC190,
  TA500,
  TA739,
  TA750,
  TA100,
  TA130,
  MS800,
  MS120,
  MS160,
  MAD,
  TG532,
  TG709,
  TG354,
  AIO532,
  JUL,
  V443,
  V473,
  V384,
  G19,
  P236,
  FILT,
  M354,
  M591,
  FILTC,
  C313,
  C192,
  MUG236,
  C65,
  AD100,
  AD591,
  AD354,
  B100,
  B740,
  Q887,
  Q118,
  PQ118,
  F887,
  F591,
  IA100,
  IA150,
  IA200,
  F500,
  A591,
  WJ75,
  JT19,
  C295,
} from '../../assets/Stanley'




export const StanleyDB=[
  {
    id: 1,
    img: M236,
    modelo: 'Mate Stanley 236 Ml',
    categoria:'mates',
  },
  {
    id: 2,
    img: M200,
    modelo: 'Mate Stanley 200 Ml',
    categoria:'mates',
  },
  {
    id: 3,
    img: TC591,
    modelo: 'Termo Stanley Classic 591 Ml',
    categoria:'termos',
  },
  {
    id: 4,
    img: TC950,
    modelo: 'Termo Stanley Clasico 950 Ml Con Manija Y Tapon Cebador',
    categoria:'termos',
  },
  {
    id: 5,
    img: TC140,
    modelo: 'Termo Stanley Clasico 1.4 Lts. Asa Plegable',
    categoria:'termos',
  },
  {
    id: 6,
    img: TC190,
    modelo: 'Termo Stanley Clasico 1,9 Lts',
    categoria:'termos',
  },  
  {
    id: 7,
    img: TA500,
    modelo: 'Termo Stanley Adventure TO-GO 500 ML',
    categoria:'termos',
  },
  {
    id: 8,
    img: TA739,
    modelo: 'Termo Stanley Adventure 739 ML',
    categoria:'termos',
  },
  {
    id: 9,
    img: TA750,
    modelo: 'Termo Stanley Adventure TO-GO 750 ML',
    categoria:'termos',
  },
  {
    id: 10,
    img: TA100,
    modelo: 'Termo Stanley Adventure 1 Lt C/Tapon Cebador',
    categoria:'termos',
  },
  {
    id: 11,
    img: TA130,
    modelo: 'Termo Stanley Adventure TO-GO 1.3 Lts',
    categoria:'termos',
  },
  {
    id: 12,
    img: MS800,
    modelo: 'Termo Stanley Mate-System 800 Ml',
    categoria:'termos',
  },
  {
    id: 13,
    img: MS120,
    modelo: 'Termo Stanley Mate-System 1.2 Lts',
    categoria:'termos',
  },
  {
    id: 14,
    img: MS160,
    modelo: 'Termo Stanley Mate-System 1.6 Lts',
    categoria:'termos',
  },
  {
    id: 15,
    img: MAD,
    modelo: 'Mochila termica Stanley Madeleine 14 Lts.',
    categoria:'alimentos',
  },
  {
    id: 16,
    img: TG532,
    modelo: 'Termo Stanley alimento Adventure TO-GO 532 ML',
    categoria:'alimentos',
  },
  {
    id: 17,
    img: TG709,
    modelo: 'Termo Stanley alimento Adventure TO-GO 709 ML',
    categoria:'alimentos',
  },
  {
    id: 18,
    img: TG354,
    modelo: 'Termo Stanley alimento Adventure TO-GO 354 ML',
    categoria:'alimentos',
  },
  {
    id: 19,
    img: AIO532,
    modelo: 'All In One Adventure 532Ml Ss/Grey',
    categoria:'alimentos',
  },
  {
    id: 20,
    img: JUL,
    modelo: 'Bolso termico Stanley Julienne 6,8 Lts',
    categoria:'alimentos',
  },
  {
    id: 21,
    img: V443,
    modelo: 'Vaso de Cerveza Stanley The Golden Hour Prismatic 444 ml',
    categoria:'bar',
  },
  {
    id: 22,
    img: V384,
    modelo: 'Vaso de Cerveza Stanley The Golden Hour Prismatic 408 ml',
    categoria:'bar',
  },
  {
    id: 23,
    img: V473,
    modelo: 'VASO PINTA STANLEY 473 ML',
    categoria:'bar',
  },
  {
    id: 24,
    img: G19,
    modelo: 'Growler Stanley 1.9 Lts',
    categoria:'bar',
  },
  {
    id: 25,
    img: P236,
    modelo: 'Petaca Stanley 236 ML',
    categoria:'bar',
  },
  {
    id: 26,
    img: FILT,
    modelo: 'Set filtro de cafe Stanley pour over + Camp mug',
    categoria:'cafe',
  },
  {
    id: 27,
    img: M354,
    modelo: 'Camp Mug Stanley 354 Ml',
    categoria:'cafe',
  },
  {
    id: 29,
    img: FILTC,
    modelo: 'Filtro de cafe Stanley Pour over',
    categoria:'cafe',
  },
  {
    id: 28,
    img: M591,
    modelo: 'Botella Térmica Stanley Neverleak Mug 591 Ml.',
    categoria:'cafe',
  },
  {
    id: 30,
    img: C313,
    modelo: 'Taza Stanley Daybreak Cup 313Ml',
    categoria:'cafe',
  },
  {
    id: 31,
    img: C192,
    modelo: 'Taza Stanley Daybreak Cup + Plato 192Ml',
    categoria:'cafe',
  },
  {
    id: 32,
    img: MUG236,
    modelo: 'Taza Vaso Térmico Stanley Camp Mug 236 Ml',
    categoria:'cafe',
  },
  {
    id: 33,
    img: C65,
    modelo: 'Pocillo Stanley Daybreak Cup 65Ml',
    categoria:'cafe',
  },
  {
    id: 34,
    img: AD354,
    modelo: 'Botella térmica Stanley All day slim 354 ml.',
    categoria:'hidratacion',
  },
  {
    id: 35,
    img: AD591,
    modelo: 'Botella térmica Stanley All day slim 591 ml.',
    categoria:'hidratacion',
  },
  {
    id: 36,
    img: AD100,
    modelo: 'Botella térmica Stanley All day slim 1 Lt.',
    categoria:'hidratacion',
  },
  {
    id: 38,
    img: B740,
    modelo: 'Botella De Agua Stanley 740 Ml',
    categoria:'hidratacion',
  },
  {
    id: 37,
    img: B100,
    modelo: 'Botella De Agua Stanley 1 Lt',
    categoria:'hidratacion',
  },
  {
    id: 39,
    img: Q887,
    modelo: 'Vaso Stanley Quencher 887 ml.',
    categoria:'hidratacion',
  },
  {
    id: 40,
    img: Q118,
    modelo: 'Vaso Stanley Quencher 1.18 Lts',
    categoria:'hidratacion',
  },
  {
    id: 41,
    img: PQ118,
    modelo: 'Porta Quencher 1.18 lt. All Day Carry All',
    categoria:'hidratacion',
  },
  {
    id: 42,
    img: F591,
    modelo: 'Botella Térmica Stanley Flip Straw 591 Ml',
    categoria:'hidratacion',
  },
  {
    id: 43,
    img: F887,
    modelo: 'Botella Térmica Stanley Flip Straw 887 Ml',
    categoria:'hidratacion',
  },
  {
    id: 44,
    img: IA100,
    modelo: 'Botella térmica Stanley Iceflow Aerolight 1 Lt.',
    categoria:'hidratacion',
  },
  {
    id: 45,
    img: IA150,
    modelo: 'Botella térmica Stanley Iceflow Aerolight 1 .5 Lts.',
    categoria:'hidratacion',
  },
  {
    id: 46,
    img: IA200,
    modelo: 'Botella Térmica Stanley Iceflow Flip straw 2.0 1L',
    categoria:'hidratacion',
  },
  {
    id: 47,
    img: A591,
    modelo: 'Botella Térmica Stanley Aerolight 591 Ml',
    categoria:'hidratacion',
  },
  {
    id: 48,
    img: F500,
    modelo: 'Botella Stanley Flowsteady 500Ml',
    categoria:'hidratacion',
  },
  {
    id: 49,
    img: WJ75,
    modelo: 'Adventure Fast Flow Water Jug 7.5 Lts',
    categoria:'hidratacion',
  },
  {
    id: 50,
    img: JT19,
    modelo: 'Jarra Termica Stanley 1,9 Lt',
    categoria:'hidratacion',
  },
  {
    id: 51,
    img: C295,
    modelo: 'Vaso Stanley Everyday c/tapa 295ml',
    categoria:'hidratacion',
  },
]