import { 
  MINI, 
  P18, 
  P21, 
  DUOMO, 
  D380, 
  DOVAL, 
  F900, 
  S410, 
  F410, 
  F500, 
  GLAMPING, 
  HORNERO, 
  PCHICO, 
  PAMPERO, 
  CHULENGO, 
  CAMPO, 
  CSISTEMA, 
  CRIOLLA, 
  GAUCHA, 
  PAMPEANA, 
  ACHICA, 
  AGRANDE, 
  MALBEC, 
  AE360, 
  AE480, 
  NAPOLES, 
  TRHG, 
  T560, 
  T780, 
  TRHL, 
  TLEÑA, 
  POMPEYA, 
  A430, 
  A730, 
  A930, 
  A7, 
  A9, 
  A15, 
  P7500, 
  P9500, 
  P13000, 
  P13000E, 
  P20000, 
  P20000DP, 
  TR7001, 
  PRISMA, 
  CHALTEN, 
  TR13001, 
  CALAFATE, 
  PATAGONIA, 
  ARGENTINA, 
  BERLIN, 
  MADRID, 
  O890, 
  O890DP, 
  O1120, 
  O1120DP, 
  O1120CC, 
  ROMA, 
  ROMADP, 
  PARIS, 
  PARISDC, 
  PARISCC, 
  P16000, 
  HOGAR, 
  CABERNET, 
  BROOKLIN, 
  ECO 
} from '../Tromen/index'


export const Tromen=[
  {
    id:'MINI',
    modelo: 'Puma Mini',
    img: MINI,
    tipo: 'Kamado',
    diametroTotal: '40,5 cm',
    diametroCoccion: '34,5 cm',
    peso: '31 Kg',
    categoria: 'Aire Libre'
  },
  {
    id:'P18',
    modelo: 'Puma 18',
    img: P18,
    tipo: 'Kamado',
    diametroTotal: '45,5 cm',
    diametroCoccion: '38,3 cm',
    peso: '62 Kg',
    categoria: 'Aire Libre'
  },
  {
    id:'P21',
    modelo: 'Puma 21',
    img: P21,
    tipo: 'Kamado',
    diametroTotal: '54,7 cm',
    diametroCoccion: '46 cm',
    peso: '86 Kg',
    categoria: 'Aire Libre'
  },
  {
    id:'DUOMO',
    modelo: 'Duomo',
    img: DUOMO,
    tipo: 'Duomo',
    diametroTotal: '110 cm',
    alto: '30 cm',
    peso: '80 Kg',
    categoria: 'Aire Libre'
  },
  {
    id:'D380',
    modelo: 'Duomo 380',
    img: D380,
    tipo: 'Duomo',
    diametroTotal: '61 cm (41cm emparrillado)',
    alto: '101 cm',
    peso: '30 Kg',
    categoria: 'Aire Libre'
  },
  {
    id:'DOVAL',
    modelo: 'Duomo Oval',
    img: DOVAL,
    tipo: 'Duomo',
    diametroTotal: '101 cm (86cm emparrillado)',
    alto: '95,4 cm',
    peso: '50 Kg',
    categoria: 'Aire Libre'
  },
  {
    id:'S410',
    modelo: 'Smoker 410',
    img: S410,
    tipo: 'Ahumador a Leña',
    diametroTotal: '41 cm',
    alto: '85 cm',
    categoria: 'Aire Libre'
  },
  {
    id:'F410',
    modelo: 'Fogon 410',
    img: F410,
    tipo: 'Fogonero',
    diametroTotal: '41 cm',
    alto: '39,4 cm',
    peso: '14 Kg',
    categoria: 'Aire Libre'
  },
  {
    id:'F500',
    modelo: 'Fogon Cinquecento',
    img: F500,
    tipo: 'Fogonero',
    diametroTotal: '58,5 cm',
    alto: '30 cm',
    categoria: 'Aire Libre'
  },
  {
    id:'F900',
    modelo: 'Fogon Novecento',
    img: F900,
    tipo: 'Fogonero',
    diametroTotal: '90 cm',
    alto: '73,5 cm',
    categoria: 'Aire Libre'
  },
  {
    id:'GLAMPING',
    modelo: 'Glamping',
    img: GLAMPING,
    tipo: 'Parrila a Leña',
    ancho: '22 cm',
    alto: '20,2 cm',
    profundidad: '40,5 cm',
    peso: '5 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'HORNERO',
    modelo: 'Hornero',
    img: HORNERO,
    tipo: 'Parrila a Leña',
    ancho: '80 cm',
    alto: '96,6 cm',
    profundidad: '43,2 cm',
    peso: '26 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'PCHICO',
    modelo: 'Pampero Chico',
    img: PCHICO,
    tipo: 'Parrila a Leña',
    ancho: '75,8 cm',
    alto: '99,2 cm',
    profundidad: '37,2 cm',
    peso: '17 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'PAMPERO',
    modelo: 'Pampero',
    img: PAMPERO,
    tipo: 'Parrila a Leña',
    ancho: '88 cm',
    alto: '100,2 cm',
    profundidad: '49 cm',
    peso: '34 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'CHULENGO',
    modelo: 'Chulengo a Leña',
    img: CHULENGO,
    tipo: 'Parrila a Leña',
    ancho: '101 cm',
    alto: '112,7 cm',
    profundidad: '52 cm',
    peso: '39 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'CAMPO',
    modelo: 'Campo',
    img: CAMPO,
    tipo: 'Parrila a Leña',
    ancho: '124 cm',
    alto: '111 cm',
    profundidad: '55 cm',
    peso: '88 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'CSISTEMA',
    modelo: 'Campo con Sistema',
    img: CSISTEMA,
    tipo: 'Parrila a Leña',
    ancho: '119 cm',
    alto: '142 cm',
    profundidad: '56 cm',
    peso: '95 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'CRIOLLA',
    modelo: 'Criolla',
    img: CRIOLLA,
    tipo: 'Parrila a Leña',
    ancho: '114 cm',
    alto: '102 cm',
    profundidad: '51,7 cm',
    peso: '75 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'GAUCHA',
    modelo: 'Gaucha',
    img: GAUCHA,
    tipo: 'Parrila a Leña',
    ancho: '125 cm',
    alto: '114,6 cm',
    profundidad: '53 cm',
    peso: '93 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'PAMPEANA',
    modelo: 'Pampeana',
    img: PAMPEANA,
    tipo: 'Parrila a Leña',
    ancho: '140 cm',
    alto: '106,6 cm',
    profundidad: '53 cm',
    peso: '90 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'ACHICA',
    modelo: 'Angus Chica',
    img: ACHICA,
    tipo: 'Parrila a Gas',
    ancho: '61 cm',
    alto: '115 cm',
    profundidad: '57 cm',
    peso: '35,6 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'AGRANDE',
    modelo: 'Angus Grande',
    img: AGRANDE,
    tipo: 'Parrila a Gas',
    ancho: '102 cm',
    alto: '115 cm',
    profundidad: '57 cm',
    peso: '47 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'CABERNET',
    modelo: 'Cabernet',
    img: CABERNET,
    tipo: 'Parrila a Gas',
    ancho: '76 cm',
    alto: '113 cm',
    profundidad: '56 cm',
    peso: '74,5 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'MALBEC',
    modelo: 'Malbec',
    img: MALBEC,
    tipo: 'Parrila a Gas',
    ancho: '102 cm',
    alto: '113 cm',
    profundidad: '56 cm',
    peso: '85 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'A430',
    modelo: 'Angux 430',
    img: A430,
    tipo: 'Parrila a Gas',
    ancho: '43 cm',
    alto: '15,5 cm',
    profundidad: '57,5 cm',
    peso: '12 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'A730',
    modelo: 'Angux 730',
    img: A730,
    tipo: 'Parrila a Gas',
    ancho: '73 cm',
    alto: '15,5 cm',
    profundidad: '57,5 cm',
    peso: '17 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'A930',
    modelo: 'Angux 930',
    img: A930,
    tipo: 'Parrila a Gas',
    ancho: '93 cm',
    alto: '15,5 cm',
    profundidad: '57,5 cm',
    peso: '22 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'AE360',
    modelo: 'Angus E360',
    img: AE360,
    tipo: 'Parrila Eléctrica',
    ancho: '36,5 cm',
    alto: '24,5 cm',
    profundidad: '50 cm',
    peso: '10 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'AE480',
    modelo: 'Angus E480',
    img: AE480,
    tipo: 'Parrila Eléctrica',
    ancho: '52 cm',
    alto: '104,5 cm',
    profundidad: '54 cm',
    peso: '25,5 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'NAPOLES',
    modelo: 'Nápoles',
    img: NAPOLES,
    tipo: 'Horno a Gas',
    ancho: '59,6 cm',
    alto: '17 cm',
    profundidad: '52,5 cm',
    peso: '15 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'TRH-G',
    modelo: 'THR-G',
    img: TRHG,
    tipo: 'Horno a Gas',
    ancho: '60 cm',
    alto: '153 cm',
    profundidad: '86 cm',
    peso: '123,6 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'T560',
    modelo: 'Tango 560',
    img: T560,
    tipo: 'Horno a Gas',
    ancho: '56 cm',
    alto: '64 cm',
    profundidad: '75 cm',
    peso: '50 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'T780',
    modelo: 'Tango 780',
    img: T780,
    tipo: 'Horno a Gas',
    ancho: '78,8 cm',
    alto: '69 cm',
    profundidad: '51 cm',
    peso: '56 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'TRHL',
    modelo: 'TRHL',
    img: TRHL,
    tipo: 'Horno a Leña',
    ancho: '60 cm',
    alto: '147 cm',
    profundidad: '89 cm',
    peso: '140 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'TLEÑA',
    modelo: 'Tango Leña',
    img: TLEÑA,
    tipo: 'Horno a Leña',
    ancho: '61 cm',
    alto: '146,5 cm',
    profundidad: '87,7 cm',
    peso: '140 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'POMPEYA',
    modelo: 'Pompeya',
    img: POMPEYA,
    tipo: 'Horno a Leña',
    ancho: '40,7 cm',
    alto: '196 cm',
    profundidad: '90,7 cm',
    peso: '140 Kg',
    categoria: 'Gourmet'
  },
  {
    id:'ECO',
    modelo: 'Eco Tromen 7000',
    img: ECO,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 60m2',
    medidas: '46x53,9x40,9 cm',
    potencia: '7000 Kcal/h',
    peso: '36,6 Kg',
    salida: '4"',
    categoria: 'Calefaccion',
    descripcion:[
      {
        id:1,
        texto:'La salamandra doble combustión más accesible del mercado llegó para calefaccionar cada hogar argentino.'
      },
      {
        id:2,
        texto:'Manija de apertura - Salida de humos: 4" - Doble combustión.'
      }
    ]
  },
  {
    id:'A7',
    modelo: 'Austral A7',
    img: A7,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 65m2',
    medidas: '44x54x41,3 cm',
    potencia: '7000 Kcal/h',
    peso: '-',
    salida: '4"',
    categoria: 'Calefaccion',
    descripcion:[
      {
        id:1,
        texto:'Toda la fuerza del fuego, en un calefactor accesible'
      },
      {
        id:2,
        texto:'Piso enlozado - Mayor superficie vidriada - Laterales curvos - Doble combustión - Salida de Humo: 4” - Mayor emisión calórica por convección y radiación'
      }
    ]
  },
  {
    id:'A9',
    modelo: 'Austral A9',
    img: A9,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 90m2',
    medidas: '47x66,3x41,2 cm',
    potencia: '9000 Kcal/h',
    peso: '-',
    salida: '4"',
    categoria: 'Calefaccion',
    descripcion:[
      {
        id:1,
        texto:'Toda la fuerza del fuego, en un calefactor accesible'
      },
      {
        id:2,
        texto:'Piso enlozado - Mayor superficie vidriada - Laterales curvos - Doble combustión - Salida de Humo: 4” - Mayor emisión calórica por convección y radiación'
      }
    ]
  },
  {
    id:'A15',
    modelo: 'Austral A15',
    img: A15,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 120m2',
    medidas: '57x69,7x46 cm',
    potencia: '15000 Kcal/h',
    peso: '-',
    salida: '6"',
    categoria: 'Calefaccion',
    descripcion:[
      {
        id:1,
        texto:'Toda la fuerza del fuego, en un calefactor accesible'
      },
      {
        id:2,
        texto:'Piso enlozado - Mayor superficie vidriada - Laterales curvos - Doble combustión - Salida de Humo: 6” - Mayor emisión calórica por convección y radiación'
      },
      {
        id:3,
        texto:'OPCIONAL: Horno con calor envolvente'
      }
    ]
  },
  {
    id:'P7500',
    modelo: 'Pehuen 7500',
    img: P7500,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 80m2',
    medidas: '36,8x54,4x44,1 cm',
    potencia: '7500 Kcal/h',
    peso: '41,4 Kg',
    salida: '4"',
    categoria: 'Calefaccion'
  },
  {
    id:'P9500',
    modelo: 'Pehuen 9500',
    img: P9500,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 110m2',
    medidas: '41x69,5x45 cm',
    potencia: '9500 Kcal/h',
    peso: '59,9 Kg',
    salida: '6"',
    categoria: 'Calefaccion'
  },
  {
    id:'P13000',
    modelo: 'Pehuen 13000',
    img: P13000,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 120m2',
    medidas: '55,8x79,6x45 cm',
    potencia: '13000 Kcal/h',
    peso: '74,6 Kg',
    salida: '6"',
    categoria: 'Calefaccion'
  },
  {
    id:'P13000E',
    modelo: 'Pehuen 13000 Esquinero',
    img: P13000E,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 120m2',
    medidas: '55,8x77x55 cm',
    potencia: '13000 Kcal/h',
    peso: '74,6 Kg',
    salida: '6"',
    categoria: 'Calefaccion'
  },
  {
    id:'P20000',
    modelo: 'Pehuen 20000',
    img: P20000,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 160m2',
    medidas: '70,7x77x60 cm',
    potencia: '20000 Kcal/h',
    peso: '108 Kg',
    salida: '6"',
    categoria: 'Calefaccion'
  },
  {
    id:'P20000DP',
    modelo: 'Pehuen 20000 Doble Puerta',
    img: P20000DP,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 160m2',
    medidas: '70,7x77x60 cm',
    potencia: '20000 Kcal/h',
    peso: '115 Kg',
    salida: '6"',
    categoria: 'Calefaccion'
  },
  {
    id:'TR7001',
    modelo: 'TR7001',
    img: TR7001,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 90m2',
    medidas: '49,4x74,4x41,1 cm',
    potencia: '8000 Kcal/h',
    peso: '62 Kg',
    salida: '6"',
    categoria: 'Calefaccion'
  },
  {
    id:'PRISMA',
    modelo: 'Prisma',
    img: PRISMA,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 90m2',
    medidas: '66,5x78,4x51 cm',
    potencia: '8000 Kcal/h',
    peso: '75 Kg',
    salida: '6"',
    categoria: 'Calefaccion'
  },
  {
    id:'CHALTEN',
    modelo: 'Chaltén',
    img: CHALTEN,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 110m2',
    medidas: '56,1x84,2x45 cm',
    potencia: '9000 Kcal/h',
    peso: '76,6 Kg',
    salida: '6"',
    categoria: 'Calefaccion'
  },
  {
    id:'TR13001',
    modelo: 'TR13001',
    img: TR13001,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 120m2',
    medidas: '76,8x87,9x76,5 cm',
    potencia: '13000 Kcal/h',
    peso: '76,5 Kg',
    salida: '6"',
    categoria: 'Calefaccion'
  },
  {
    id:'CALAFATE',
    modelo: 'Calafate',
    img: CALAFATE,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 130m2',
    medidas: '80,3x92,4x52,7 cm',
    potencia: '16000 Kcal/h',
    peso: '108 Kg',
    salida: '6"',
    categoria: 'Calefaccion'
  },
  {
    id:'PATAGONIA',
    modelo: 'Patagonio',
    img: PATAGONIA,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 160m2',
    medidas: '59x95,1x63 cm',
    potencia: '21000 Kcal/h',
    peso: '132,6 Kg',
    salida: '8"',
    categoria: 'Calefaccion'
  },
  {
    id:'ARGENTINA',
    modelo: 'Argentina',
    img: ARGENTINA,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 210m2',
    medidas: '88,1x99,3x51,6 cm',
    potencia: '22000 Kcal/h',
    peso: '150 Kg',
    salida: '8"',
    categoria: 'Calefaccion'
  },
  {
    id:'BERLIN',
    modelo: 'Berlín',
    img: BERLIN,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 130m2',
    medidas: '65x84x66 cm',
    potencia: '18000 Kcal/h',
    salida: '8"',
    categoria: 'Calefaccion'
  },
  {
    id:'MADRID',
    modelo: 'Madrid',
    img: MADRID,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 130m2',
    medidas: '80,5x69x45 cm',
    potencia: '18000 Kcal/h',
    peso: '120 Kg',
    salida: '6"',
    categoria: 'Calefaccion'
  },
  {
    id:'O890',
    modelo: 'Ombu 890',
    img: O890,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 210m2',
    medidas: '90x77,7x59 cm',
    potencia: '23000 Kcal/h',
    peso: '184,6 Kg',
    salida: '8"',
    categoria: 'Calefaccion'
  },
  {
    id:'O890DP',
    modelo: 'Ombu 890 Doble Puerta',
    img: O890DP,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 210m2',
    medidas: '90x78,8x66,2 cm',
    potencia: '23000 Kcal/h',
    peso: '200 Kg',
    salida: '8"',
    categoria: 'Calefaccion'
  },
  {
    id:'O1120',
    modelo: 'Ombu 1120',
    img: O1120,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 240m2',
    medidas: '113x89,2x59 cm',
    potencia: '27000 Kcal/h',
    peso: '220 Kg',
    salida: '10"',
    categoria: 'Calefaccion'
  },
  {
    id:'O1120DP',
    modelo: 'Ombu 1120 Doble Puerta',
    img: O1120DP,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 240m2',
    medidas: '113x89,2x66,4 cm',
    potencia: '27000 Kcal/h',
    peso: '220 Kg',
    salida: '10"',
    categoria: 'Calefaccion'
  },
  {
    id:'O1120CC',
    modelo: 'Ombu 1120 Cuatro Caras',
    img: O1120CC,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 240m2',
    medidas: '113x80,2x66,4 cm',
    potencia: '27000 Kcal/h',
    peso: '240 Kg',
    salida: '10"',
    categoria: 'Calefaccion'
  },
  {
    id:'ROMA',
    modelo: 'Roma',
    img: ROMA,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 210m2',
    medidas: '90x77,7x59 cm',
    potencia: '23000 Kcal/h',
    peso: '190 Kg',
    salida: '8"',
    categoria: 'Calefaccion'
  },
  {
    id:'ROMADP',
    modelo: 'Roma Doble Puerta',
    img: ROMADP,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 210m2',
    medidas: '90,7x78,8x66,2 cm',
    potencia: '23000 Kcal/h',
    peso: '200 Kg',
    salida: '8"',
    categoria: 'Calefaccion'
  },
  {
    id:'PARIS',
    modelo: 'Paris',
    img: PARIS,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 240m2',
    medidas: '113x89,2x59 cm',
    potencia: '27000 Kcal/h',
    peso: '220 Kg',
    salida: '10"',
    categoria: 'Calefaccion'
  },
  {
    id:'PARISDC',
    modelo: 'Paris Doble Cara',
    img: PARISDC,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 240m2',
    medidas: '113x89,2x66,4 cm',
    potencia: '27000 Kcal/h',
    peso: '240 Kg',
    salida: '10"',
    categoria: 'Calefaccion'
  },
  {
    id:'PARISCC',
    modelo: 'Paris Cuatro Caras',
    img: PARISCC,
    tipo: 'Calefactor a Leña',
    ambiente: 'Hasta 240m2',
    medidas: '113x89,2x59 cm',
    potencia: '27000 Kcal/h',
    peso: '240 Kg',
    salida: '10"',
    categoria: 'Calefaccion'
  },
  {
    id:'P16000',
    modelo: 'P16000',
    img: P16000,
    tipo: 'Calefactor a Leña Insertable',
    ambiente: 'Hasta 130m2',
    medidas: '77,7x62,4x42,6 cm',
    potencia: '16000 Kcal/h',
    peso: '80 Kg',
    salida: '6"',
    categoria: 'Calefaccion',
    descripcion:[
      {
        id:1,
        texto:'La solidez de la línea Pehuen en un calefactor compacto'
      },
      {
        id:2,
        texto:'Largo máximo de la leña 65 cm - Diámetro de la salida de humo 6 " - 2 derivadores de calor posterior - Interior recubierto de ladrillo refractario - Regulador de temperatura'
      }
    ]
  },
  {
    id:'HOGAR',
    modelo: 'Hogar',
    img: HOGAR,
    tipo: 'Calefactor a Leña Insertable',
    ambiente: 'Hasta 130m2',
    medidas: '76x72,1x43,4 cm',
    potencia: '16000 Kcal/h',
    peso: '88 Kg',
    salida: '6"',
    categoria: 'Calefaccion'
  },
  {
    id:'BROOKLIN',
    modelo: 'Brooklin',
    img: BROOKLIN,
    tipo: 'Calefactor a Gas',
    ambiente: 'Hasta 60m2',
    medidas: '67,4x50X25 cm',
    potencia: '7000 Kcal/h',
    peso: '20 Kg',
    categoria: 'Calefaccion'
  },
]